.container {
    max-width: 1000px;
    margin: 20px auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    font-family: 'Avenir', sans-serif;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #37474F;
}

.loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.toastNotification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    padding: 50px 50px 40px 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    z-index: 1000;
    opacity: 0.95;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.loadingIcon {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    animation: spin 4s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.preferencesInput {
    width: 90%;
    padding: 1rem;
    border: 2px solid #E5E7EB;
    border-radius: 8px;
    margin: 1rem 0;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.2s ease;
    font-family: 'Avenir', sans-serif;
    resize: vertical;
    min-height: 120px;
    line-height: 1.5;
    display: block;
}

.preferencesInput:focus {
    border-color: #9CA3AF;
}

.saveButton {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 0.75rem 2rem;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-family: 'Avenir', sans-serif;
    display: block;
    margin: 0;
}

.saveButton:hover {
    background-color: #0056b3;
}

.saveContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.savedMessage {
    color: #28a745;
    font-size: 1rem;
    animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
    0% { opacity: 1; }
    70% { opacity: 1; }
    100% { opacity: 0; }
}

.savingIcon {
    width: 20px;
    height: 20px;
    animation: spin 4s linear infinite;
} 