/* {
  margin: 0;
}*/

/*body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
  font-family: 'Avenir', sans-serif;
  max-width: 100%;
}*/

.chatApp {
  font-family: 'Avenir', sans-serif;
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  /*position: relative;*/
  /*width: 100%;*/
  height: 100vh;
  height: 100dvh;
  /*background-image: url('/public/background_image.jpg');*/
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('/public/background_image_2.jpg');
  background-size: cover;
  background-position: center;
  /*max-width: 100%;*/
}

.chatHistory {
  display: flex;
  flex-direction: column;
  /*align-items: flex-start;*/
  gap: 10px;
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px 20px 0px 20px;
  scroll-behavior: smooth;
  /*margin-bottom: 150px;*/
  /*position: relative;*/
  /*background-color: grey;
  border-radius: 20px;*/
}

.chatHistory h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.chatHistory strong {
  font-weight: bold;
}

.chatHistory ul {
  margin-left: 20px;
  list-style-type: disc;
}

.chatHistory p {
  margin: 10px 0;
}

.userMessageWrapper {
  text-align: right;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  /*display: inline-block;*/
  max-width: 100%;
  /*align-self: flex-end;*/
  /*word-wrap: break-word;*/
  /*display: flex;*/
  /*flex-shrink: 1;*/
  /*flex: 0 0 auto;*/
  /*justify-content: flex-end;*/
  align-self: flex-end;
}

.botMessageWrapper {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 100%;
  margin-bottom: 10px;
  align-self: flex-start;
  user-select: none;
}

.botMessage {
  text-align: left;
  background-color: #d1e7ff;
  padding: 10px;
  border-radius: 10px;
  /*margin-right: 10px;*/
  word-wrap: break-word;
  /*flex-grow: 1;*/
  max-width: 70%;
  /*align-self: flex-start;*/
}

.botMessage h3 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.botMessage ul {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 20px;
}

.botMessage ol {
  margin-top: 15px;
}

.initialPrompt {
  text-align: center;
  margin-top: 25vh;
  margin-bottom: 20px;
}

.initialPrompt h1 {
  font-size: 2.3rem;
  font-weight: 400;
}

.chatInput {
  display: flex;
  gap: 10px;
  /*width: 97.5%;*/
  padding: 15px 20px;
  /*background-color: white;*/
  /*position: fixed;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*right: 0;*/
  flex-shrink: 0;
}

.initialChatInput {
  max-width: 600px;
  width: 475px;
  margin: 0 auto 40px auto;
  position: relative;
}

.chatInput input {
  flex-grow: 1;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 25px;
  outline: none;
  font-size: 16px;
  transition: border-color 0.3s ease;
  font-family: 'Avenir', sans-serif;
}

.chatInput button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px; /* Round shape */
  height: 50px; /* Round shape */
  background-color: #007BFF; /* iMessage blue */
  color: white;
  border: none;
  border-radius: 50%; /* Makes the button round */
  cursor: pointer;
  padding: 0;
  transition: background-color 0.3s ease;
}

.chatInput button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.chatInput button svg {
  width: 20px;
  height: 20px;
  stroke: white;
  stroke-width: 2px;
  fill: none;
}

.chatInput button svg path {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.promptButtons {
  display: flex;
  flex-wrap: wrap;
  /* Allows buttons to wrap to the next line */
  justify-content: center;
  /* Center the buttons */
  gap: 20px 40px;
  /*margin-top: 20px;*/
  max-width: 500px;
  /* Limit the width of the button container */
  margin: 0px auto 0px auto;
  /*bottom: 400px;*/
  position: relative;;
}

.promptButtons button {
  padding: 12px 20px;
  background-color: #e0e0e0;
  /* Change background color to grey */
  color: black;
  /* Change text color to black */
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Avenir', sans-serif;
  width: 45%;
  /* Each button takes up nearly half the container width */
}

.promptButtons button:hover {
  background-color: #aaa;
  /* Slightly darker grey on hover */
}

.typingIndicator {
  display: flex;
  gap: 5px;
  /*margin-right: none;*/
  /*align-self: flex-start;*/
}

.dot {
  animation: blink 1.5s infinite;
  font-size: 20px;
  color: white;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.saveMessageButton {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  opacity: 0;
  /* Start hidden */
  position: relative;
  /*right: 20px;*/
  /* Position the button on the right side */
  z-index: 1;
  visibility: hidden;
  margin-left: 10px;
  font-family: 'Avenir', sans-serif;
  margin-top: auto;
  /*margin-bottom: 60px;*/
}

.botMessageWrapper:hover .saveMessageButton {
  opacity: 1;
  visibility: visible;
}

.saveMessageButton:hover {
  background-color: #218838;
}

.toastNotification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: black;
  padding: 50px 50px 40px 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  z-index: 1000;
  opacity: 0.95;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.proTipToastNotification {
  position: fixed;
  top: 85px;
  /*bottom: 20px;*/
  left: 50%;
  transform: translateX(-50%);
  background-color: #9ACCA6;
  color: black;
  padding: 15px 20px;
  border-radius: 10px;
  font-size: 1rem;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
  animation: fadeIn 0.3s ease, fadeOut 0.3s ease 9.7s;
  /*width: 35%;*/
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%, -20px);
  }

  to {
    opacity: 1;
    transform: translateX(-50%, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(-50%, 0);
  }

  to {
    opacity: 0;
    transform: translateX(-50%, -20px);
  }
}

.checkmarkIcon {
  margin-bottom: 20px;
}

/* Styles for the context menu */
.contextMenu {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100px;
}

.contextMenu button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 175px;
}

.contextMenu .contextMenuCancelButton {
  background-color: grey;
}

.contextMenu button:hover {
  background-color: #0056b3;
}

/* MOBILE STYLES */

@media (max-width: 768px) {
  .initialPrompt {
    margin-top: 15vh;
  }

  .initialPrompt h1 {
    font-size: 2rem;
    font-weight: 400;
  }

  .promptButtons {
    margin-bottom: 60px;
  }

  .promptButtons button {
    width: 90%;
  }

  .botMessage {
    max-width: 95%;
  }

  .chatInput {
    max-width: 90%;
  }

  .toastNotification {
    width: 225px;
    padding: 40px 30px 30px 30px;
  }

  .proTipToastNotification {
    width: 85%;
  }

}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.loadingIcon {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}