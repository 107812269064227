/* Base container styles */
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
  font-family: 'Avenir', sans-serif;
}

/* Add body background color */
body {
  background-color: #f0f4f8;
}

/* Desktop-specific styles */
.desktopContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
}

.weekNavigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.navButton {
  padding: 0.5rem 1rem;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Avenir', sans-serif;
  font-weight: bold;
}

/* Desktop table styles */
.tableWrapper {
  overflow-x: auto;
  margin-bottom: 2rem;
}

.table {
  width: 100%;
  /*border-collapse: collapse;*/
  border: none;
  table-layout: fixed;
}

.th, .td {
  padding: 0.5rem;
  /*border: 1px solid #ccc;*/
  border: none;
  text-align: center;
}

.td:first-child {
  font-weight: bold;
}

.td:first-child, .th:first-child {
  width: 100px;
}

.td:not(:first-child), .th:not(:first-child) {
  width: calc((100% - 100px) / 7);
}

.dateText {
  font-size: 0.8rem;
  color: #777;
  display: block;
}

/* Desktop meal button styles */
.mealButton {
  width: 100%;
  height: 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.mealButtonSelected {
  background-color: #0070f3;
  color: white;
}

.mealButtonUnselected {
  background-color: #f0f0f0;
}

.mealButtonDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Desktop footer styles */
.footer {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.rightSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 0 auto 0 325px;  /* Changed from 'margin: 0 auto' to shift left */
}

.preferencesButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #f0f0f0;
  color: #333;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-family: 'Avenir', sans-serif;
  height: 36px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 2.5rem;
}

.preferencesButton:hover {
  background-color: #e0e0e0;  /* Slightly darker grey on hover */
}

.preferencesLink {
  text-decoration: none;
}

.mealCounter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 0.5rem 0;
  width: 100px;
}

.mealCount {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-right: 0.5rem;
}

.mealLabel {
  font-size: 0.9rem;
  color: #666;
}

.submitButton {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  width: 100%;
  max-width: 100px;
  font-family: 'Avenir', sans-serif;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .mobileContainer {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
  }

  .mobileContent {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  /* Selection view specific padding */
  .mobileContainer[data-view="selection"] .mobileContent {
    padding-bottom: calc(1rem + 140px);
  }

  /* Meal Plan view specific padding */
  .mobileContainer[data-view="mealPlan"] .mobileContent {
    padding-bottom: 2rem;
  }

  .dayCard {
    background: #fafafa;
    border-radius: 12px;
    padding: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    box-sizing: border-box;  /* Include padding in width calculation */
  }

  .mealOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.75rem;
  }

  .mobileMealButton {
    flex: 1;
    padding: 0.75rem 0.5rem;
    border: 2px solid #ddd;
    border-radius: 8px;
    background: white;
    color: #000;
    font-size: 0.9rem;
    font-family: 'Avenir', sans-serif;
    transition: all 0.2s ease;
    text-align: center;
  }

  .mobileMealButtonSelected {
    background: #007BFF;
    border-color: #007BFF;
    color: white;
  }

  .submitSection {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  /* Add mobile-specific title spacing */
  .mobileContainer[data-view="mealPlan"] .title {
    margin-bottom: 0.5rem;  /* Reduce from default spacing */
  }

  /* Add mobile-specific recipe navigation spacing */
  .mobileContainer[data-view="mealPlan"] .recipeNavigation {
    margin-bottom: 0.75rem;  /* Reduce from 2rem */
  }

  .mobileContainer[data-view="mealPlan"] .recipeName {
    padding: 0.5rem 1rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    max-width: 100%;
    font-size: 0.95rem;  /* Slightly reduce font size to help with wrapping */
    line-height: 1.4;
  }

  .mobileContainer[data-view="mealPlan"] .recipeButton {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background: white;
    border-radius: 8px;
    position: relative;
  }

  .mobileContainer[data-view="mealPlan"] .recipeContent {
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 40px 0rem 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobileContainer[data-view="mealPlan"] .mealType,
  .mobileContainer[data-view="mealPlan"] .recipeName {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .mobileContainer[data-view="mealPlan"] .regenerateButton {
    position: absolute;
    top: 8px;
    right: 8px;
    opacity: 1;  /* Always visible on mobile */
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 50%;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;  /* Ensure it's above the recipe button */
    margin: 0;
  }

  .mobileContainer[data-view="mealPlan"] .regenerateIcon {
    width: 14px;
    height: 14px;
  }

  .mobileContainer[data-view="mealPlan"] .mealsList {
    width: 100%;
    box-sizing: border-box;
  }

  .mobileContainer[data-view="mealPlan"] .dialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 1rem;
  }

  .mobileContainer[data-view="mealPlan"] .dialog {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .mobileContainer[data-view="mealPlan"] .dialog h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    text-align: center;
  }

  .mobileContainer[data-view="mealPlan"] .dialog p {
    margin: 0 0 1rem 0;
    color: #666;
    text-align: center;
  }

  .mobileContainer[data-view="mealPlan"] .feedbackInput {
    width: 100%;
    box-sizing: border-box;  /* Include padding in width calculation */
    min-height: 100px;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 0 0 1rem 0;
    font-family: inherit;
    resize: vertical;  /* Only allow vertical resizing */
    text-align: center;
  }

  .mobileContainer[data-view="mealPlan"] .dialogButtons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1.5rem;
  }

  .mobileContainer[data-view="mealPlan"] .cancelButton,
  .mobileContainer[data-view="mealPlan"] .submitButton {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    cursor: pointer;
  }

  .mobileContainer[data-view="mealPlan"] .cancelButton {
    background: #f0f0f0;
  }

  .mobileContainer[data-view="mealPlan"] .submitButton {
    background: #007bff;
    color: white;
  }

  .mobileContainer[data-view="mealPlan"] .submitButton:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  .mobileContainer[data-view="recipe"] .recipeLayout {
    padding: 1rem;
    display: flex;
    flex-direction: column;  /* Stack sections vertically */
  }

  .mobileContainer[data-view="recipe"] .recipeSection {
    margin-bottom: 2rem;
    width: 100%;  /* Take full width */
  }

  .mobileContainer[data-view="recipe"] .ingredientsList {
    list-style-type: disc;  /* Simple bullet points */
    padding-left: 2rem;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .mobileContainer[data-view="recipe"] .ingredientsList li {
    margin-bottom: 0.75rem;
    line-height: 1.5;
    padding: 0;
    border: none;
  }

  .mobileContainer[data-view="recipe"] .instructionsList {
    padding-left: 2rem;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .mobileContainer[data-view="recipe"] .instructionsList li {
    margin-bottom: 1rem;
    line-height: 1.5;
    padding-right: 1rem;
  }

  .mobileContainer[data-view="recipe"] .recipeImageContainer {
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 12px;
    overflow: hidden;
  }

  .mobileContainer[data-view="recipe"] .recipeImage {
    width: 100%;
    height: auto;
    display: block;
  }

  .mobileContainer[data-view="recipe"] .imagePlaceholder {
    width: 100%;
    padding: 2rem;
    background: #f8f9fa;
    text-align: center;
    color: #6c757d;
    border-radius: 12px;
    font-style: italic;
  }

  .mobilePreferencesWrapper {
    display: flex;
    justify-content: center;
    margin: 0 0 0.55rem 0;
  }

  .mobilePreferencesButton {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: #f0f0f0;
    color: #333;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-family: 'Avenir', sans-serif;
    height: 36px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .mobilePreferencesButton:hover {
    background-color: #e0e0e0;
  }
}

/* Past day styling */
.pastDay {
  color: #999;
}

.pastDay .dateText {
  color: #999;
}

.pastDay + .td .mealButton,
td:has(+ .pastDay) .mealButton {
  opacity: 0.6;
  background-color: #f0f0f0;
}

.mealButtonDisabledDistinct {
  background-color: #f0f0f0 !important;
  cursor: not-allowed;
  opacity: 0.5;
  position: relative;
}

.mealButtonDisabledDistinct::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 4px,
    transparent 4px,
    transparent 8px
  );
  border-radius: 4px;
}

/* Update the mobile week navigation styles */
.mobileWeekNavigation {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.mobileNavButton {
  flex: 1;
  padding: 0.75rem 1rem;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  color: #495057;
  cursor: pointer;
  transition: all 0.2s ease;
}

.mobileNavButton:hover {
  background-color: #e9ecef;
}

/* Update the day header styles */
.dayHeader {
  margin-bottom: 1rem;
  padding: 0 0.5rem;
}

.dayName {
  font-weight: 600;
  color: #495057;
  font-size: 1rem;
}

.dayDate {
  color: #6c757d;
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

/* Update mobile title styles */
.mobileTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #212529;
  text-align: center;
  margin-bottom: 0.25rem;
  padding: 0 1rem;
}

/* Recipe tile container */
.mealsList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Recipe tile styles for mobile */
.recipeTile {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1.25rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: 'Avenir', sans-serif;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.recipeTile:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.recipeTileContent {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  text-align: center;
  width: 100%;
}

.mealType {
  font-size: 0.8rem;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: block;
  font-family: 'Avenir', sans-serif;
  width: 100%;
  padding: 0.5rem 0;
}

.recipeName {
  font-size: 1rem;
  color: #333;
  font-weight: 500;
  display: block;
  font-family: 'Avenir', sans-serif;
  line-height: 1.4;
  width: 100%;
  padding: 0.5rem 0;
}

/* No meals message styling */
.noMealsMessage {
  color: #666;
  font-size: 0.9rem;
  padding: 1rem;
  text-align: center;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px dashed #dee2e6;
  font-family: 'Avenir', sans-serif;
  width: 100%;
  box-sizing: border-box;
}

/* Mobile recipe button styles */
.recipeButton {
  width: 100%;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 12px; /* Increased border radius */
  padding: 0.25rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: 'Avenir', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0rem;
  text-align: center;
}

.recipeButton:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.recipeButton .mealType {
  font-size: 0.8rem;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: block;
  width: 100%;
  padding: 0.5rem 0;
}

.recipeButton .recipeName {
  font-size: 1rem;
  color: #333;
  font-weight: 500;
  display: block;
  line-height: 1.4;
  width: 100%;
  padding: 0.5rem 0;
}

/* Mobile-specific grocery list button styles */
@media (max-width: 768px) {
  .groceryListContainer {
    padding: 0 1rem 1rem 1rem;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
  }

  .groceryListButton {
    width: 100%;
    max-width: calc(100% - 2rem); /* Account for container padding */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 12px;
    padding: 1rem;
    font-family: 'Avenir', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .groceryListButton:hover {
    background-color: #0056b3;
  }

  .groceryListButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  /* Style for the loading state */
  .buttonContent {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .buttonLoadingIcon {
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Desktop-specific meal plan styles */
@media (min-width: 769px) {
  .tableWrapper {
    overflow-x: auto;
    margin: 2rem 0;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    background: white;
    table-layout: fixed;
    /*border: none;*/
  }

  .th:first-child,
  .td:first-child {
    width: 100px;
    text-align: center;
    padding-left: 1rem;
    font-weight: 600;
    color: #333;
  }

  .th:not(:first-child),
  .td:not(:first-child) {
    width: calc((100% - 100px) / 7);
  }

  .th {
    padding: 1rem 1rem 1.25rem 1rem;
    text-align: center;
    /*border: 1px solid #e0e0e0;*/
    font-weight: 600;
    color: #333;
    width: calc(100% / 7);
    position: relative;
    border-radius: 8px;
  }

  .th.today {
    background-color: rgba(0, 123, 255, 0.1);
    position: relative;
  }

  .tbody .tr .td {
    border: none;
  }

  .dateText {
    display: block;
    font-size: 0.8rem;
    color: #666;
    font-weight: normal;
    margin-top: 0.25rem;
  }

  .recipeTd {
    padding: 12px 5px 0px 5px;
    vertical-align: top;
    /*border: 1px solid #e0e0e0;*/
    height: 200px;
  }

  .recipeTile {
    background: rgb(250, 250, 250);
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 0px 10px 5px 10px;
    margin-bottom: 0.75rem;
    cursor: pointer;
    transition: all 0.2s ease;
    height: 125px;
  }

  .recipeTile:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .recipeTileContent {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: left;
  }

  .recipeTile .mealType {
    font-size: 0.8rem;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  .recipeTile .recipeName {
    font-size: 0.9rem;
    color: #333;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4;
    height: 4.2em; /* Force exact height for 3 lines */
    margin: 0; /* Ensure no margin affects the height */
    padding: 0; /* Ensure no padding affects the height */
  }

  .groceryListContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .groceryListButton {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    font-family: 'Avenir', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  .groceryListButton:hover {
    background-color: #0056b3;
  }

  .groceryListContent {
    margin: 2rem auto;
    max-width: 800px;
    padding: 0 36rem 0 2rem;
  }

  .ingredientsList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-width: 100%;
  }

  .ingredientsList li {
    padding: 0.75rem 0;
    border-bottom: 1px solid #eee;
    font-size: 1rem;
    color: #333;
  }

  .ingredientsList li:last-child {
    border-bottom: none;
  }

  .loadingMessage {
    text-align: center;
    color: #666;
    padding: 2rem 0;
    font-size: 1rem;
  }

  /* Add specific selector for Meal Plan view table */
  [data-view="mealPlan"] .th:first-child,
  [data-view="mealPlan"] .td:first-child {
    display: none;
  }

  /* Adjust column widths for Meal Plan view */
  [data-view="mealPlan"] .th,
  [data-view="mealPlan"] .td {
    width: calc(100% / 7) !important;
  }
}

/* Loading overlay styles */
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.toastNotification {
  background: white;
  border-radius: 12px;
  padding: 1rem 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  color: #333;
  font-family: 'Avenir', sans-serif;
}

.loadingIcon {
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

/* Update mobile grocery list styles to match desktop */
@media (max-width: 768px) {
  .ingredientsList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .ingredientsList li {
    padding: 1rem 0;
    border-bottom: 1px solid #eee;
    font-size: 1rem;
    color: #333;
  }

  .ingredientsList li:last-child {
    border-bottom: none;
  }

  .groceryListContent {
    padding: 0 1rem;
  }
}

.regenerateButton {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.recipeTile:hover .regenerateButton {
  opacity: 1;
}

.regenerateButton:hover {
  transform: rotate(180deg);
}

.regenerateIcon {
  width: 14px;
  height: 14px;
}

.regenerateDialog {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 500px;
  text-align: center;
}

.regenerateDialog h3 {
  margin: 0 0 1rem 0;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
}

.regenerateDialog p {
  text-align: center;
  color: #666;
  margin-bottom: 1.5rem;
}

.regenerateTextarea {
  width: calc(100% - 1.5rem);
  min-height: 100px;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 1rem 0;
  font-family: 'Avenir', sans-serif;
  resize: vertical;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.dialogButtons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.cancelButton, 
.dialogButtons .submitButton {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-family: 'Avenir', sans-serif;
  font-size: 0.9rem;
  cursor: pointer;
  min-width: 100px;
}

.cancelButton {
  background-color: #f0f0f0;
  color: #333;
}

.dialogButtons .submitButton {
  background-color: #007bff;
  color: white;
}

.dialogButtons .submitButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@keyframes highlightRecipe {
  0% {
    outline: 2px solid transparent;
    transform: translateY(0);
  }
  20% {
    outline: 2px solid #37474F;
    transform: translateY(-2px);
  }
  80% {
    outline: 2px solid #37474F;
    transform: translateY(-2px);
  }
  100% {
    outline: 2px solid transparent;
    transform: translateY(0);
  }
}

.justRegenerated {
  animation: highlightRecipe 2s ease-in-out;
}

/* Recipe view styles */
.recipeContent {
  margin-bottom: 1rem;
}

.recipeNavigation {
  margin-bottom: 2rem;
}

/* Container style for recipe view */
.desktopContainer[data-view="recipe"] {
  max-width: 1000px;
  margin: 20px auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.recipeLayout {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 3rem;
  align-items: start;
}

.recipeInfo {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.recipeTitle {
  font-size: 2rem;
  color: #333;
  margin: 0;
}

.recipeSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sectionTitle {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
}

.ingredientsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.instructionsList {
  padding-left: 1.25rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.instructionsList li {
  line-height: 1.6;
  color: #333;
}

.recipeImageContainer {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  color: #666;
  overflow: hidden;
}

.recipeImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error {
  text-align: center;
  padding: 2rem;
  color: #dc3545;
}

.preferencesIcon {
  height: 16px;
  width: 16px;
}