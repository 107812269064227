/* OrderPreview.css */

/*body {
    font-family: 'Avenir', sans-serif;
}*/

.orderPreviewContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f8f8f8;
    /* Light background color */
}

.orderSummary {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    max-width: 400px;
    text-align: center;
}

.orderSummary h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    font-family: 'Avenir', sans-serif;
}

.orderSummary p {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
    font-family: 'Avenir', sans-serif;
}

.price {
    font-size: 18px;
    color: #000;
    margin-bottom: 20px;
    font-family: 'Avenir', sans-serif;
}

.buttonGroup {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.checkoutButton,
.backButton {
    background-color: #007bff;
    /* Stripe blue for checkout */
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    flex-grow: 1;
    font-family: 'Avenir', sans-serif;
}

.backButton {
    background-color: #6c757d;
    /* Grey for back button */
}

.checkoutButton:hover,
.backButton:hover {
    opacity: 0.9;
}

.checkoutButton:active,
.backButton:active {
    transform: scale(0.98);
    /* Subtle click animation */
}