/* {
    margin: 0;
}*/

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: 100dvh;
    font-family: 'Avenir', sans-serif;
}

.content {
    display: flex;
    flex: 1;
    overflow: hidden;
    position: relative;
}

.recipeList {
    /*width: 25%;*/
    background-color: #f0f0f0;
    padding: 20px;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    /*flex-grow: 1;*/
    display: flex;
    flex-direction: column;
    flex-basis: 25%;
}

.recipeItem {
    display: block;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
    transition: background-color 0.3s ease;
    font-family: 'Avenir', sans-serif;
}

.recipeItem:hover {
    background-color: #e0e0e0;
}

.recipeDetails {
    flex: 1;
    position: relative;
    /*width: 75%;*/
    padding: 20px 40px 40px 40px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    /*transition: width 0.3s ease;*/
}

.recipeDetails h1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 400;
}

.recipeDetails h3 {
    margin-top: 10px;
    margin-bottom: 20px;
}

.recipeDetails h4 {
    margin-bottom: 15px;
    margin-top: 0;
}

.recipeDetails ul {
    margin-bottom: 20px;
    margin-top: 0px;
}

.recipeDetails p {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    margin-top: 0;
    margin-bottom: 5px;
}

.notesButton {
    position: fixed;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #347474;
    color: white;
    border: none;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    /*font-family: 'Avenir', sans-serif;*/
    cursor: pointer;
    /*width: 40px;*/
    height: 100px;
    /*padding: 10px 20px;*/
    padding: 0px 10px 0px 15px;
    /*font-size: 16px;*/
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.notesButton:hover {
    background-color: #4A9696;
    /* Lighter shade on hover */
}

.notesIcon {
    width: 24px;
    height: 24px;
    /*display: block;*/
    margin: auto;
    /*margin-right: 100px;*/
}

.notesIconMobile {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.backIconMobile {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.notesDrawer {
    flex-shrink: 0;
    flex-basis: 25%;
    background-color: #f0f0f0;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    transform: translateX(100%);
    transition: transform 0.3s ease;
}

/*.notesDrawer.open {
    transform: translateX(0);
}*/

.notesDrawer h2 {
    font-family: 'Avenir', sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 10px;
}

.notesDrawer h3 {
    font-family: 'Avenir', sans-serif;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 10px;
    margin-left: 10px;
}

.notesTextarea {
    flex: 1;
    width: 90%;
    padding: 10px;
    margin: 10px auto 30px auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: none;
    font-family: 'Avenir', sans-serif;
    font-size: 16px;
}

.saveButton {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Avenir', sans-serif;
    align-self: center;
    width: 100%;
}

.saveButton:hover {
    background-color: #0056b3;
}

.backButton {
    background-color: #347474;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1em;
    font-family: 'Avenir', sans-serif;
    transition: background-color 0.3s ease;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
}

.backButton:hover {
    background-color: #4A9696;
}

.notesButtonMobile {
    background-color: #347474;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1em;
    font-family: 'Avenir', sans-serif;
    transition: background-color 0.3s ease;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
}

.notesButtonMobile:hover {
    background-color: #4A9696;
}

.notesDrawerMobile {
    width: 100%;
    background-color: #f0f0f0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    /*margin-bottom: 10px;*/
}

.notesDrawerMobile h2 {
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 0;
}

.notesDrawerMobile h3 {
    font-weight: normal;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 0;
}

/*.notesDrawerMobile.open {
    transform: translateX(0);
}*/

.mobileButtonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

@media (max-width: 768px) {

    .recipeList {
        /*width: 100%;*/
        flex-basis: 100%;
        box-sizing: border-box;
    }

    .recipeDetails {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    .recipeItem {
        color: black;
    }

    .recipeDetails ul {
        padding-left: 30px;
        /*margin-top: 0px;*/
    }

    .recipeDetails ol {
        padding-left: 20px;
        margin-top: 0px;
    }

    .recipeDetails ol li ul {
        padding-left: 20px;
    }

    .recipeDetails h4 {
        margin-top: 0;
    }

}