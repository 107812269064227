.container {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    height: 100vh;
    height: 100dvh;
    font-family: 'Avenir', sans-serif;
    /*margin: 0;*/
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
    flex-grow: 1;
    /*text-align: center;*/
    /*padding: 20px;*/
    /*margin-bottom: 170px;*/
    width: 90%;
    margin: auto;
}

.initialPrompt {
    text-align: center;
    margin-top: 175px;
    font-size: 2rem;
    margin-bottom: 10px;
    font-weight: 500;
    width: 80%;
}

.subtext {
    text-align: center;
    font-size: 1rem;
    color: #555;
    margin-bottom: 60px;
    width: 80%;
}

.fileInput {
    display: none;
    /* Hide the file input */
}

.uploadButton {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 15px 30px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Avenir', sans-serif;
}

.uploadButton:hover {
    background-color: #0056b3;
}

.fileList {
    margin-top: 20px;
    text-align: left;
}

.fileList h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.fileList ul {
    list-style-type: disc;
    margin-left: 20px;
}

.fileList li {
    font-size: 1rem;
    color: #333;
}

.processingText {
    margin-top: 350px;
}

.backButton {
    margin-top: 10px;
    background-color: #e0e0e0;
    /*color: #333;*/
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    /*margin-bottom: 20px;*/
    /*display: inline-block;*/
    /*margin-left: 0;*/
    margin-right: auto;
    transition: background-color 0.3s ease;
    font-family: 'Avenir', sans-serif;
    text-decoration: none;
}

.backButton:hover {
    background-color: #c0c0c0;
}