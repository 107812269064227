.websiteHeader {
    display: flex;
    align-items: center;
    z-index: 1;
    padding: 10px;
    flex-shrink: 0;
    font-family: 'Avenir', sans-serif;
}

.logoImage {
    width: 50px;
    margin-right: 10px;
}

.websiteName {
    font-size: 24px;
}

.plusBadge {
    background: linear-gradient(145deg, #FFD700, #FFDF00, #FFC700);
    color: #505050;
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: bold;
    margin-left: 10px;
    border: 1px solid #FFC700;
}

.subscribeButton, .authButtons {
    margin-left: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 15px;
    cursor: pointer;
    font-family: 'Avenir', sans-serif;
    font-size: 14px;
}

.subscribeButton:hover, .authButtons:hover {
    background-color: #0056b3;
}

.authButtons {
    font-size: 14px;
    margin-right: 0px;
}

.desktopNavigation {
    display: flex;
    margin-left: 15px;
    align-items: center;
    margin-right: 0px;
    gap: 10px;
    position: relative;
}

.myCookbookLink, .chatLink {
    text-decoration: none;
}

.myCookbookButton, .chatButton, .profileButton {
    background-color: #347474;
    color: white;
    border: none;
    padding: 10px 20px 10px 15px;
    border-radius: 15px;
    cursor: pointer;
    font-family: 'Avenir', sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
}

.cookbookIcon, .chatIcon, .profileIcon {
    height: 20px;
    margin-right: 10px;
}

.myCookbookButton:hover, .chatButton:hover, .profileButton:hover, .dropdownItem:hover {
    background-color: #4A9696;
}

.profileButton {
    user-select: none;
    position: relative;
}

.dropdownMenu {
    position: absolute;
    background-color: #347474;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 215px;
    top: 50px;
    right: 0;
}

.dropdownItem {
    padding: 10px;
    border: none;
    width: 100%;
    text-align: left;
    background-color: transparent;
    font-family: 'Avenir', sans-serif;
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
}

.dropdownIcon {
    height: 18px;
    width: 18px;
    margin-right: 10px;
}

.bmIcon {
    height: 20px;
    width: 20px;
    margin-right: 20px;
    object-fit: contain;
    flex-shrink: 0;
}

.userName {
    margin: 0 0 10px 0px;
}

.messageCount {
    background-color: #f0f0f0;
    padding: 10px 15px;
    border-radius: 15px;
    text-align: center;
    font-size: 14px;
    font-family: 'Avenir', sans-serif;
    margin: 0 0 0 auto;
    width: fit-content;
}

.greenBackground {
    background-color: #d4edda;
    /* Light green */
    color: black;
    /* Dark green text */
}

.orangeBackground {
    background-color: #fd7e14;
    /* Light yellow */
    color: black;
    /* Dark yellow text */
}

.redBackground {
    background-color: #e55362;
    /* Light red */
    color: black;
    /* Dark red text */
}

.logoutButton {
    position: absolute;
    bottom: 20px;
    /*width: 100%;*/
    /*text-align: center;*/
    /*padding: 20px 0;*/
    /*background-color: #347474;*/
    /* Match the menu's color */
    /*color: white;*/
    /*cursor: pointer;*/
    /*border: none;*/
    /*font-family: 'Avenir', sans-serif;*/
    /*font-size: 14px;*/
    /*margin-bottom: 10px;*/
}

/* MOBILE STYLES */

@media (max-width: 768px) {
    /*.authButtons {
        margin-right: 10px;
    }*/
}

.dropdownLink {
    text-decoration: none;
    width: 100%;
}

.mealPlannerLink {
    text-decoration: none;
}

.mealPlannerButton {
    background-color: #347474;
    color: white;
    border: none;
    padding: 10px 20px 10px 15px;
    border-radius: 15px;
    cursor: pointer;
    font-family: 'Avenir', sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
}

.mealPlannerIcon {
    height: 20px;
    margin-right: 10px;
}

.mealPlannerButton:hover {
    background-color: #4A9696;
}

.mealPlannerButton.active,
.myCookbookButton.active,
.chatButton.active {
    background-color: white;
    color: black;
    border: 2px solid #347474;
    padding: 8px 18px 8px 13px;
}

.mealPlannerButton.active:hover,
.myCookbookButton.active:hover,
.chatButton.active:hover {
    background-color: #f8f8f8;
    color: black;
    border: 2px solid #347474;
}

.dropdownItem.active {
    background-color: white;
    color: black;
    border: 2px solid #347474;
    padding: 8px 8px 8px 8px;  /* Adjusted padding for dropdown items */
}

.dropdownItem.active:hover {
    background-color: #f8f8f8;
    color: black;
    border: 2px solid #347474;
}

.dropdownItem.active .dropdownIcon {
    /* Ensure icon alignment when button is active */
    margin-right: 8px;  /* Adjusted for the border */
}

.menuItem {
    display: flex;
    align-items: center;
    color: black;
    padding: 10px 0;
    text-decoration: none;
    width: 100%;
}

/* Create a container for icon and text */
.menuItemContent {
    display: flex;
    align-items: center;
    width: 160px;
    margin: 0 0 0 0px;
}